/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */

import {
    CategoryPageComponent as SourceCategoryPage,
} from "SourceRoute/CategoryPage/CategoryPage.component";
import ContentWrapper from 'Component/ContentWrapper';
import ChevronIcon from 'Component/ChevronIcon';
import { Directions } from 'Component/ChevronIcon/ChevronIcon.config';
import Footer from 'Component/Footer';

import './CategoryPage.override.style';

/** @namespace Route/CategoryPage/Component */
export class CategoryPageComponent extends SourceCategoryPage {
    state = {
        activeLayoutType: undefined,
        isDescriptionExpanded: false
    };

    toggleDescription = () => {
        this.setState({ isDescriptionExpanded: !this.state.isDescriptionExpanded });
    }

    renderCmsBlock() {
        const { isDescriptionExpanded } = this.state;
        const { category: { cms_block }, isCurrentCategoryLoaded } = this.props;

        const descriptionExpandedActive = isDescriptionExpanded ? 'active' : '';

        if (!cms_block || !this.displayCmsBlock() || !isCurrentCategoryLoaded) {
            return null;
        }

        const { content, disabled } = cms_block;

        if (disabled) {
            return null;
        }

        return (
            <div block="CategoryPage" elem="CMSWrapper" mix={{block:descriptionExpandedActive}}>
                <div block="CategoryPage" elem="CMS" dangerouslySetInnerHTML={ { __html: content } } />
                <div block="CategoryPage" elem="CMSReadmore" >
                    <div block="CategoryPage" elem="CMSReadmorebtn" onClick={this.toggleDescription} >
                        {descriptionExpandedActive ? __('Read Less') : __('Read More') } <ChevronIcon direction={ Directions.RIGHT } />
                    </div>
                </div>
            </div>
        );
    }
    renderContent() {
        return (
            <>
                { this.renderFilterOverlay() }
                { this.renderCategoryDetails() }
                { this.renderMiscellaneous() }
                { this.renderCategoryProductList() }
            </>
        );  
    }
    render() {
        const hideProducts = !this.displayProducts();
        const { totalItems, mobileBackdrop } = this.props;

        return (
            <main block="CategoryPage" mods={ { noResults: totalItems === 0 } } ref={ this.categoryPageRef }>
                <ContentWrapper
                  wrapperMix={ {
                      block: 'CategoryPage',
                      elem: 'Wrapper',
                      mods: { hideProducts },
                  } }
                  label="Category page"
                >
                    <div
                      block="CategoryPage"
                      elem="MobileBackdrop"
                      ref={ mobileBackdrop }
                    />
                    <div
                      block="CategoryPage"
                      elem="Loader"
                      mods={ {} }
                    />
                    { this.renderContent() }
                    { this.renderCmsBlock() }
                </ContentWrapper>
                <Footer isVisibleOnMobile />
            </main>
        );
    }
}

export default CategoryPageComponent;
