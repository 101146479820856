Mosaic.addPlugins([require('/var/www/html/src/localmodules/my-app/node_modules/@elightwalk/infinite_scroll/src/plugin/ProductListComponent.plugin.js')]);
/* eslint-disable react/jsx-no-bind */
/* eslint-disable */
/* stylelint-disable */
/* eslint-disable spaced-comment */

import {
    ProductListComponent as SourceProductListComponent
} from 'SourceComponent/ProductList/ProductList.component';
import ProductListPage from 'Component/ProductListPage';

/**
 * List of category products
 * @class ProductList
 * @namespace Component/ProductList/Component
 */
export class ProductListComponent extends SourceProductListComponent {
    renderTitle() {
        const { title } = this.props;

        if (!title) {
            return null;
        }

        return (
            <div block="page-section" elem="head">
                <h2 block="page-section" elem="title">{ title }</h2>
            </div>
        );
    }
    renderPage(props = {}) {
        const {
            isInfiniteLoaderEnabled,
            loadPage,
            isLoading,
            isVisible,
            mix,
            isPlp,
            title,
        } = this.props;
        const {
            items = [],
            // keys = [],
            pageNumber = 0,
            selectedFilters = {},
            wrapperRef,
            key,
        } = this._processProps(props);

        return (
            <ProductListPage
              key={ key }
              isInfiniteLoaderEnabled={ isInfiniteLoaderEnabled }
              updatePages={ loadPage }
              isLoading={ isLoading }
              isVisible={ isVisible }
              mix={ mix }
              items={ items }
              pageNumber={ pageNumber }
              selectedFilters={ selectedFilters }
              wrapperRef={ wrapperRef }
              isPlp={ isPlp }
              title={ title }
            />
        );
    }
}

export default ProductListComponent;
